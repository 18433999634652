@use 'Variables' as vars;
.interactive-map
{
    text-align: center;
}
.dis-scroll
{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  
}
.dis-scroll::-webkit-scrollbar {
    display: none;
}

.interactive-map__mode{
    position: fixed;
    left: vars.$map-sidebar-width;
    top:0px;
    overflow: visible;
    .maplang
    {
        &--active
        {
            @extend .maplang;
            left: -15px;
            background-color: vars.$menu-lang-active-color;
            border-radius: 7px 0px 0px 7px;
            width: 34px;
        }
        img{
            width: 30px;
            height: 20px;
        }
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 20px;
        background-color: vars.$menu-lang-inactive-color;
        padding: 5px;
        margin: 3px;
    }
    .scenario-item
    {
        &--active
        {
            @extend .scenario-item;
            left: -15px;
            border-radius: 7px 3px 3px 7px;
            background-color: vars.$menu-scenario-color;
            color: vars.$menu-lang-inactive-color-text;
            width: 28px;
        }
        cursor: pointer;
        left:5px;
        height: 125px;
        width: 20px;
        position: relative;
        writing-mode: tb-rl;
        padding: 7px;
        margin: 10px 0px 10px 0px;
        font-family: Arial, Helvetica, sans-serif;
        font-size:16pt;
        color: vars.$menu-scenario-color-inactive;
        font-weight: bold;
        background-color: vars.$menu-scenario-color-bg;
        border-radius: 0px 3px 3px 0px;
    }
}
@media (max-width: vars.$breakpoint-mobile) {
    .interactive-map__menu{
        display: none;
        &.menu-mobile{
            display:block !important;
        }
    }
}

.interactive-map__menu{
    width: vars.$map-sidebar-width;
    height: 100vh;
    z-index: 2;
    color: vars.$map-sidebar-reg-text-color;
    font-family: Arial, Helvetica, sans-serif;
    ul {
        list-style-type: none;
        padding: 5px;
        margin-top: 0px;
        padding-top: 0px;
    }
    h1{
        user-select: none;
        font-size: 36pt;
        color: vars.$menu-title-color;
        text-shadow: #000 1px 0 2px;
        margin: 10px;
    }
    &.menu--closed
    {
        height: 0px !important;
    }
    &.menu-mobile{
        display:none;
        position: fixed;
        z-index: 2;
        align-items: center;
        .header
        {
            width:vars.$map-sidebar-width-mobile;
            height: 40px;
            background-color: vars.$menu-mobile-header-color;
            margin-left: 5px;
            padding-left: 5px;
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid vars.$menu-mobile-border-color;
            border-radius: 7px 7px 0px 0px;
            .ham{
                width: 30px;
                height: 30px;
                border: 0;
                margin: 0;
                padding: 0;
                cursor: pointer;
                background-size:cover;
                background-position: center;
                margin-right: 15px;
            }
            .map-mode-moblie
            {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .maplang
                {
                    &--active
                    {
                        @extend .maplang;
                        background-color: vars.$menu-lang-active-color;
                    }
                    img{
                        width: 30px;
                        height: 20px;
                    }
                    cursor: pointer;
                    position: relative;
                    width: 30px;
                    height: 20px;
                    background-color: gray;
                    padding: 5px;
                    margin: 3px;
                    border-radius: 5px;
                }
                .scenario-item
                {
                    &--active
                    {
                        @extend .scenario-item;
                        background-color: vars.$menu-scenario-color;
                        color: vars.$menu-lang-inactive-color-text;
                    }
                    cursor: pointer;
                    left:5px;
                    height: 25px;
                    width: 25px;
                    position: relative;
                    padding: 3px;
                    margin: 10px 0px 10px 0px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size:16pt;
                    color: vars.$menu-scenario-color-inactive;
                    font-weight: bold;
                    background-color: vars.$menu-scenario-color-bg;
                    border-radius: 4px;
                    margin-left: 7px;
                    margin-right: 7px;
                }
                span{
                    padding: 0px 7px 0px 7px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14pt;
                    font-weight: bold;
                }
            }
        }
        .content
        {
            position: relative;
            width:vars.$map-sidebar-width-mobile;
            background: linear-gradient(90deg, vars.$mobile-menu-grad-start 0%, vars.$mobile-menu-grad-end 100%);
            margin-left: 5px;
            padding-left: 5px;
            margin-top: 0px;
            padding-top: 0px;
            height: calc(100vh - 100px);
            border: 2px solid vars.$menu-mobile-border-color;
            border-top: none;
            border-radius: 0px 0px 7px 7px;
            transition: height 0.3s linear 0s;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            &--closed
            {
                @extend .content;
                height: 0px;
            }
        }
        .menu-item
        {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    &--calendar
    {
        background: linear-gradient(90deg, vars.$menu-calendar-grad-start 0%, vars.$menu-calendar-grad-end 100%);
        width: 320px;
        border: 3px solid vars.$menu-title-color;
        border-radius: 10px;
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        z-index: 2;
       
        margin-top: 5px;
        .row
        {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            margin: 15px 0px 5px 0px;
            &.mobile
            {
                margin: 3px 0px 3px 0px;
            }
            .day
            {
                width: 45px;
                height: 45px;
                cursor: pointer;
                background-size: 45px 45px;
                background-position: 0px 0px;
                margin: 4px;
            }
        }
    }
    &--calendar-header
    {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        width: vars.$menu-calendar-width;
        height: 20px;
        border-bottom: 3px solid vars.$menu-title-color;
        p{
            width: 54px;
            height: 20px;
            background-size: cover;
            background-position: 0;
            display: block;
            margin: 0;
            padding: 0;
            border: 0;
            background-size: 52px 68px;
        }
    }
    &--content
    {
        width: vars.$menu-content-width;
        height: vars.$menu-content-height;
        background-size: vars.$menu-content-width vars.$menu-content-height;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    &--type-container
    {
        .type-markers
        {
            position: relative;
        }
        overflow: hidden hidden;
        height: calc(100vh - vars.$menu-container-diff);
        position: absolute;
        bottom: 0;
        left: 0;
        .custom-scrollmarker
        {
            position: absolute;
            width: 15px;
            height:70px;
            background-color: vars.$menu-scrollmarker-bg;
            border: 1px solid vars.$menu-title-color;
            border-radius: 3px;
            bottom: 0px;
            right: 0px;
        }
        ul
        {
            margin:0;
        }
    }
    &--padding
    {
        width: vars.$menu-content-width;
        height: calc(100vh - vars.$menu-content-height);
        background-size: vars.$menu-content-width vars.$menu-content-height;
        background-position-y: vars.$menu-background-shift;
    }

    .menu-item
    {
        width: vars.$menu-item-width;
        height: vars.$menu-item-height;
        padding: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        user-select: none;
        margin-bottom: 0px;
        padding-bottom: 15px;
        padding-top: 15px;
        border-bottom: 1px solid vars.$menu-item-border-color;
        margin-left: 20px;
        margin-right: 20px;

        &--disabled
        {
            @extend .menu-item;
            color: vars.$map-sidebar-dis-text-color;
        }

        &:hover{
            @extend .menu-item;
            background-color: vars.$map-sidebar-hov-bg-color;
        }

        &__icon
        {
            width: 45px;
            height: 45px;
            margin-left: 0px;
            margin-right: 0px;
        }

        &__check
        {
            width: 45px;
            height: 45px;
            margin-left: 2px;
            margin-right: 0px;
        }

        &__title
        {
            font-size: 15pt;
            font-family: Arial, Helvetica, sans-serif;
            padding-left: 7px;
            font-weight: bold;
        }
    }
}