@use 'Variables' as vars;

@media (max-width: vars.$breakpoint-mobile) {
    .interactive-map__content{
        &--inner
        {
            width: 100vw !important;
            .coords{
                span.reg
                {
                    display: none;
                }
                span.mob{
                    display:inline-block !important;
                }
            }
        }
        &--outer
        {
            width: 100vw !important;
        }
        &--inner
        {
            .internal
            {
                &--overlay
                {
                    left: 20px !important;
                    top: 70px !important;
                }
            }
        }
    }
}

.interactive-map__content{
    &--outer
    {
        // background: linear-gradient(90deg, #ab9d90 0%, #b1997d 100%);
        height: 100vh;
        width: calc(100vw - vars.$map-sidebar-width);
        position: relative;
        border: 0;
        margin: 0;
        padding: 0;
        user-select: none;
    }
    &--inner
    {
        height: 100vh;
        width: calc(100vw - vars.$map-sidebar-width);
        position: relative;
        z-index: 1;
        user-select: none;
        .mapborder
        {
            position: absolute;
            // background-color: red;
        }

        .internal
        {
            position: absolute;
            // background-repeat: no-repeat;
            cursor: crosshair;
            user-select: none;
            // border: 1px solid black;
            
            &--visible-border
            {
                // border: 5px solid red;
                opacity: 10%;
                position: relative;
            }
            &--inside
            {   
                @extend .internal;
                border: 0;
                user-select: none;
                overflow: hidden;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                svg{
                    position: relative;
                }
            }
            &--border--corner
            {
                @extend .internal;
                border: 0;
                margin: 0;
                padding: 0;
                background-size: 100% 100%;
            }
            &--border--background
            {
                @extend .internal;
                border: 0;
                margin: 0;
                padding: 0;
                background-color: #b0a292ff;
            }
            &--border--line
            {
                @extend .internal;
                border: 0;
                margin: 0;
                padding: 0;
                background-size: cover;
                // background-size: 100% 100%;
                background-position-x: left;
                background-position-y: top;
            }

            &--border--clip
            {
                @extend .internal;
                border: 1px solid red;
                margin: 0;
                padding: 0;
                background-color: brown;
                opacity: 50%;
            }

            &--cell
            {
                @extend .internal;
                user-select: none;
                border: 0;
                // background-repeat: no-repeat;
            }
            &--cell-vector
            {
                @extend .internal;
                user-select: none;
                border: 0;
                width: 100%;
                height: 100%;
                background-color: wheat;
                // background-repeat: no-repeat;
            }
            &--overlay
            {
                position:fixed;
                left: 430px;
                top: 30px;
                opacity: 50%;
                background-color: #000;
                color: #FFF;
                border: 1px solid black;
                border-radius: 15px;
                z-index: 4;
                text-align: left;
                font-size: 16pt;
                padding: 20px;
                font-family: 'Courier New', Courier, monospace;
            }
        }
        
        .coords{
            position:absolute;
            font-family: 'Courier New', Courier, monospace;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 20px;
            background-color: black;
            color: white;
            opacity: 0.50;
            font-size: 17px;
            padding: 5px;
            text-align: center;
            z-index: 5;
            span.mob{
                display: none;
            }
        }

        .marker-layer{
            position:absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            cursor: crosshair;

            .marker{
                pointer-events:none;
                position:absolute;
                width: 27px;
                height: 40px;
               
                margin-top: -40px;
                margin-left: -13.5px;

                &__icon
                {
                    width: 27px;
                    height: 40px;
                   
                }
            }
        }

        .contour-layer{
            position:absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            cursor: crosshair;

            .contour{
                pointer-events:none;
                position:absolute;
                margin: 0;
                padding: 0;
                border: 0;
            }
        }
    }

   
}