@use 'Variables' as vars;

:root {
    --app-height: 100%;
}
html,
body
{
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    @media not all and (hover:hover) {
        height: var(--app-height);
    }
}

.interactive-map{
    width: 100vw;
    height: calc(var(--app-height, 1vh) * 100);
    overflow: hidden;
    background-color: gray;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}