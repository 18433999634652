$map-sidebar-width: 400px;
$map-sidebar-reg-bg-color: #754930;
$map-sidebar-dis-bg-color: #534136;
$map-sidebar-hov-bg-color: #eecd90ff;
$map-sidebar-reg-text-color: #7d3e41ff;
$map-sidebar-dis-text-color: #7d3e41ff;
$breakpoint-mobile: 1024px;
$map-sidebar-width-mobile: 360px;
$menu-lang-active-color: rgb(194, 108, 58);
$menu-lang-inactive-color: rgb(72, 72, 72);
$menu-lang-inactive-color-text: rgb(233, 165, 88);
$menu-scenario-color: #995f3dff;
$menu-scenario-color-inactive: rgb(148, 136, 114);
$menu-scenario-color-bg:rgb(80, 78, 75);
$menu-title-color: #ac5736ff;
$menu-mobile-header-color: rgb(199, 154, 77);
$menu-mobile-border-color: #995f3d;
$mobile-menu-grad-start:rgba(150,124,80,1);
$mobile-menu-grad-end: rgba(220,184,116,1);
$menu-calendar-grad-start: #967c50;
$menu-calendar-grad-end: #dcb874;
$menu-calendar-width: 320px;
$menu-content-width: 408px;
$menu-content-height: 822px;
$menu-container-diff: 260px;
$menu-scrollmarker-bg: rgba(218, 131, 49, 255);
$menu-background-shift: -79px;
$menu-item-width: 340px;
$menu-item-height: 30px;
$menu-item-border-color: #4e3956ff;